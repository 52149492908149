import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import commentReducer from "../features/comment/CommentSlice";
import authReducer from "../features/auth/AuthSlice";
import customerReducer from "../features/customer/CustomerSlice";
import { commentApi } from "../features/comment/service/comment.service";
import { authApi } from "features/auth/service/auth.service";
import { customerApi } from "features/customer/service/customer.service";
import { couponApi } from "features/coupon/services/coupon.service";
import { newsletterApi } from "features/newsletter/service/newsletter.service";
import WebsiteReducer from "../features/website/WebsiteSlice";
import { websiteApi } from "features/website/service/website.service";
import EventReducer from "../features/events/EventSlice";
import { eventApi } from "features/events/service/event.service";
import { productApi } from "features/events/service/product.service";

export const store = configureStore({
	reducer: {
		comment: commentReducer,
		[commentApi.reducerPath]: commentApi.reducer,
		auth: authReducer,
		[authApi.reducerPath]: authApi.reducer,
		customer: customerReducer,
		[customerApi.reducerPath]: customerApi.reducer,
		[couponApi.reducerPath]: couponApi.reducer,
		[newsletterApi.reducerPath]: newsletterApi.reducer,
		website: WebsiteReducer,
		[websiteApi.reducerPath]: websiteApi.reducer,
		event: EventReducer,
		[eventApi.reducerPath]: eventApi.reducer,
		[productApi.reducerPath]: productApi.reducer,
	},

	// Thêm middleware để enable các tính năng caching invalidation, polling của rtk-query
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(commentApi.middleware)
			.concat(authApi.middleware)
			.concat(customerApi.middleware)
			.concat(couponApi.middleware)
			.concat(newsletterApi.middleware)
			.concat(websiteApi.middleware)
			.concat(eventApi.middleware)
			.concat(productApi.middleware),
});

// Nếu dùng các tính  năng reFetchOnFocus / reFetchOnReconnect
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
