import React, { Suspense, useEffect, useLayoutEffect, useState } from "react";
import "./App.css";
import "swiper/css";
import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";
// import "swiper/css/effect-coverflow";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectToken, setToken, setUser } from "features/auth/AuthSlice";
import { LoginJson, User } from "features/auth/types/auth.type";
import { RouterProvider } from "react-router-dom";
import {
	setDetailProduct,
	setProductId,
	setType,
} from "features/comment/CommentSlice";
import { getRouter } from "utils/getSetting";
import { ErrorBoundary } from "react-error-boundary";
import MyFallbackComponent from "templates/MyFallbackComponent";
import AppWithLocale from "AppWithLocale";
import { Provider } from "react-redux";
import Website from "common/constants/Website";
import { store } from "app/store";
import { createRoot } from "react-dom/client";
import Helper from "common/utils/helper";
import NLLetter from "features/newsletter/components/NLLetter";
import useRender from "features/website/renders/useRender";
import FloatButton from "features/website/components/floatButton/FloatButton";
import LiveIcon from "library/icons/LiveIcon";
import ContactIcon from "library/icons/ContactIcon";
import SettingApi from "common/constants/SettingApi";
import CurrentRated from "features/comment/components/totalReviewRated/CurrentRated";
import Event from "features/events/Event";
//////////////////////
function App() {
	const dispatch = useAppDispatch();
	const token = useAppSelector(selectToken);
	const fullname = useAppSelector((state) => state.customer.fullname);
	const { renderElement } = useRender();
	///////////////////////////////
	// bắt thẻ div của feature muốn render
	const container = document.getElementById("nperp-features")!;

	// bắt thẻ div của newsletter trong footer 2 version
	const container_desktop = document.getElementById("newsletter_desktop");
	const container_mobile = document.getElementById("newsletter_mobile");
	const container_newsletter = !Helper.isMobile()
		? container_desktop
		: container_mobile;

	// div event (20/5/2024)
	const container_event = document.getElementById("nperp_event");

	//
	const container_countRate_desktop =
		document.getElementById("nperp_rate_desktop");
	const container_countRate_mobile =
		document.getElementById("nperp_rate_mobile");
	const container_countRate_detail =
		document.getElementById("nperp_rate_detail");
	// feature
	const feature = container.dataset.feature!;
	const feature_newsletter = container_newsletter?.dataset.feature;

	// lấy id và type của product của comment
	const id = container.dataset.id;
	const type = container.dataset.type;

	// Lấy id và type của product của currentRate
	const idRate = !Helper.isMobile()
		? container_countRate_desktop?.dataset.id
		: container_countRate_mobile?.dataset.id;
	const typeRate = !Helper.isMobile()
		? container_countRate_desktop?.dataset.type
		: container_countRate_mobile?.dataset.type;
	const idRateDetail = container_countRate_detail?.dataset.id;
	const typeRateDetail = container_countRate_detail?.dataset.type;

	///////////////////////////////
	const [loading, setLoading] = useState<boolean>(true);
	///////////////////////////////

	// lấy routing theo feature tương ứng
	const router = getRouter({ feature: feature });
	const router_newsletter = feature_newsletter
		? getRouter({ feature: feature_newsletter })
		: null;
	///////////////////////////////

	// newsletter
	// useLayoutEffect(() => {
	// 	if (container_newsletter) {
	// 		const root = createRoot(container_newsletter);
	// 		router_newsletter &&
	// 			root.render(
	// 				<AppWithLocale>
	// 					<Provider store={store}>
	// 						<div id="features_nteam">
	// 							<NLLetter></NLLetter>
	// 						</div>
	// 					</Provider>
	// 				</AppWithLocale>
	// 			);
	// 	}
	// }, [container_newsletter]);

	// CurrentRated
	useLayoutEffect(() => {
		if (idRate && typeRate) {
			if (container_countRate_desktop) {
				const root = createRoot(container_countRate_desktop);
				root.render(
					<AppWithLocale>
						<Provider store={store}>
							<CurrentRated
								item_id={idRate}
								item_type={typeRate || "product"}></CurrentRated>
						</Provider>
					</AppWithLocale>
				);
			}

			if (container_countRate_mobile) {
				const root = createRoot(container_countRate_mobile);
				root.render(
					<AppWithLocale>
						<Provider store={store}>
							<CurrentRated
								item_id={idRate}
								item_type={typeRate || "product"}></CurrentRated>
						</Provider>
					</AppWithLocale>
				);
			}
		}
	}, [
		container_countRate_mobile,
		container_countRate_desktop,
		idRate,
		typeRate,
	]);

	useLayoutEffect(() => {
		if (idRate && typeRate) {
			if (container_countRate_desktop) {
				const root = createRoot(container_countRate_desktop);
				root.render(
					<AppWithLocale>
						<Provider store={store}>
							<CurrentRated
								item_id={idRate}
								item_type={typeRate || "product"}></CurrentRated>
						</Provider>
					</AppWithLocale>
				);
			}
		}
	}, [container_countRate_desktop, idRate, typeRate]);

	useLayoutEffect(() => {
		if (container_countRate_detail) {
			if (idRateDetail && typeRateDetail) {
				const root = createRoot(container_countRate_detail);
				root.render(
					<AppWithLocale>
						<Provider store={store}>
							<CurrentRated
								item_id={idRateDetail}
								item_type={typeRateDetail || "product"}></CurrentRated>
						</Provider>
					</AppWithLocale>
				);
			}
		}
	}, [container_countRate_detail, idRateDetail, typeRateDetail]);

	// render event
	useLayoutEffect(() => {
		if (container_event) {
			const root = createRoot(container_event);
			root.render(
				<AppWithLocale>
					<Provider store={store}>
						<Event />
					</Provider>
				</AppWithLocale>
			);
		}
	}, [container_event]);
	// float button
	// useEffect(() => {
	// 	if (feature === "login") {
	// 		renderElement({
	// 			elements: [
	// 				{
	// 					id: "nperp_quickcontact_live",
	// 					node: (
	// 						<FloatButton
	// 							id="nperp_live"
	// 							icon={
	// 								<LiveIcon
	// 									size={Helper.isMobile() ? "mobile" : "desktop"}></LiveIcon>
	// 							}
	// 							type={Website.FLOAT_BUTTON.LIVE}
	// 							key_setting={SettingApi.QUICKCONTACT.k_title_live}
	// 						/>
	// 					),
	// 				},
	// 				{
	// 					id: "nperp_quickcontact_contact",
	// 					node: (
	// 						<FloatButton
	// 							id="nperp_contact"
	// 							icon={
	// 								<ContactIcon
	// 									size={
	// 										Helper.isMobile() ? "mobile" : "desktop"
	// 									}></ContactIcon>
	// 							}
	// 							type={Website.FLOAT_BUTTON.CONTACT}
	// 							key_setting={SettingApi.QUICKCONTACT.k_title_contact}
	// 						/>
	// 					),
	// 				},
	// 			],
	// 		});
	// 	}
	// }, [container, feature]);
	// debug
	useLayoutEffect(() => {
		if (process.env.NODE_ENV === "production") {
			console.warn = function () {};
			console.error = function () {};
			console.log = function () {};
		}
	}, []);

	///////////////////////////////

	// loyalty
	useLayoutEffect(() => {
		const unLockLoyalty = document.getElementById("unlock_loyalty");
		if (unLockLoyalty && window !== undefined) {
			const token = localStorage.getItem("MAIN:token");
			const user = localStorage.getItem("MAIN:user");

			if (token && user) {
				const userParse = JSON.parse(user) as LoginJson;

				const tokenParse = JSON.parse(token);
				dispatch(setToken(tokenParse));
				dispatch(setUser(userParse));

				const HEADER_LOGIN = document.querySelector(".my-account");
				const FOOTER_LOGIN_MOBILE = document.querySelector("#account-login-no");

				if (HEADER_LOGIN && HEADER_LOGIN.parentElement) {
					HEADER_LOGIN.innerHTML = Website.LOGIN.HEADER_LOGIN(userParse.user);
				}
				if (FOOTER_LOGIN_MOBILE && FOOTER_LOGIN_MOBILE.parentElement) {
					FOOTER_LOGIN_MOBILE.parentElement.innerHTML =
						Website.LOGIN.FOOTER_LOGIN_MOBILE();
				}
			} else {
				const HEADER_UNLOGIN = document.querySelector(".my-account");
				const FOOTER_UNLOGIN_MOBILE =
					document.querySelector("#account-login-no");
				if (HEADER_UNLOGIN) {
					HEADER_UNLOGIN.innerHTML = Website.LOGIN.HEADER_UNLOGIN();
				}
				if (FOOTER_UNLOGIN_MOBILE && FOOTER_UNLOGIN_MOBILE.parentElement) {
					FOOTER_UNLOGIN_MOBILE.parentElement.innerHTML =
						Website.LOGIN.FOOTER_UNLOGIN_MOBILE();
				}
			}
		}
	}, [token, fullname]);
	// comment
	useLayoutEffect(() => {
		if (feature === "comment") {
			type && dispatch(setType(type));
			id &&
				dispatch(
					setProductId(typeof id === "string" ? id : JSON.stringify(id))
				);
			const nperpWrapper = document.querySelector(".nperp_rate");
			if (nperpWrapper) {
			}

			if (container) {
				const imgProduct = container.dataset.image;
				const titleProduct = container.dataset.title;
				const vendor = container.dataset.vendor;
				const gender = container.dataset.gender;
				const handle = container.dataset.handle;
				if (titleProduct && vendor && gender && handle && imgProduct) {
					dispatch(
						setDetailProduct({
							handle: handle,
							vendor: vendor,
							title: titleProduct,
							linkImageProduct: imgProduct,
							gender: gender,
						})
					);
				}
			}
		}
		setLoading(false);
	}, [type, id, container, feature]);

	///////////////////////////////

	return (
		<ErrorBoundary FallbackComponent={MyFallbackComponent}>
			<div id="features_nteam">
				{loading ? (
					<div
						style={{
							position: "relative",
						}}>
						Vui lòng chờ giây lát...
					</div>
				) : (
					<Suspense
						fallback={
							<div
								style={{
									position: "relative",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									minHeight: "50vh",
									width: "100%",
								}}>
								Vui lòng chờ giây lát...
							</div>
						}>
						<RouterProvider router={router} />
					</Suspense>
				)}
			</div>
		</ErrorBoundary>
	);
}

export default App;
