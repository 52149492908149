import React from "react";
import { useGetSetingEventQuery } from "./service/event.service";
import SettingContanst from "./data/shop";
import {
	CustomLandingPage,
	ZoneDataBrandList,
	ZoneDataInfo,
	ZoneDataProduct,
	ZoneDataSubBanner,
	ZoneDataSupport,
} from "./types/event.type";
import EZoneDataBanner from "./components/EZoneDataBanner";
import { ZoneDataBanner } from "./types/event.type";
import { DataZone } from "./types/event.type";
import EZoneDataSubBanner from "./components/EZoneDataSubBanner";
import styles from "./css/event.module.css";
import EZoneDataInfo from "./components/EZoneDataInfo";
import "swiper/css";
import EZoneDataProduct from "./components/EZoneDataProduct";
import EZoneDataBrand from "./components/EZoneDataBrand";
import EZoneDataSupport from "./components/EZoneDataSupport";
import { useGetDataProductQuery } from "./service/product.service";
export default function Event() {
	const { data, error, isLoading } = useGetSetingEventQuery(
		SettingContanst.custom_landing_page,
		{}
	);

	const i = {
		company_id: 1,
		key: "custom_landing_page",
		group: "COMMON",
		id: 986,
		lang: "default",
		value: {
			data: [
				{
					zone_name: "banner",
					zone_data: {
						image_desktop:
							"//theme.hstatic.net/1000340570/1000885609/14/banner-of-the-year-desk.jpg?v=100817",
						image_mobile:
							"//theme.hstatic.net/1000340570/1000885609/14/banner-of-the-year-mobile.jpg?v=100817",
					},
				},
				{
					zone_name: "sub_banner",
					zone_data: [
						{
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_1.jpg?v=100817",
							url: "/products/mini-gift-set-burberry-london-4sp-5ml-x-4",
						},
						{
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_2.jpg?v=100817",
							url: "/products/gift-set-calvin-klein-euphoria-eau-de-parfum-3pcs-edp-100ml-edp-10ml-body-lotion-200ml",
						},
						{
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_3.jpg?v=100817",
							url: "/products/gift-set-chloe-nomade-3pcs-edp-75ml-edp-5ml-duong-the-100ml",
						},
					],
				},
				{
					zone_name: "info",
					zone_data: {
						title: "BRAND OF THE YEAR",
						descriptions: [
							"Săn Ngay Deal Khủng Từ Những Thương Hiệu Nước Hoa",
							"Được Yêu Thích Nhất của namperfume Trong Năm 2023",
						],
						slide: [
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_3.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_4.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_5.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_6.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_7.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
							},
						],
						grid: [
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_1.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_2.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_3.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_4.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_5.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_6.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_7.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_8.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_9.jpg?v=100817",
							},
						],
					},
				},
				{
					zone_name: "product",
					zone_data: {
						available_type: [
							{
								from_time: 1665371647,
								to_time: 1665371647,
							},
						],
						collection_id: 1003916686,
						collection_title: "BRANDBURBERRY",
						collection_handle: "brandburberry",
						image_desktop:
							"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
						image_mobile:
							"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
					},
				},
				{
					zone_name: "brandlist",
					zone_data: [
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
						},
					],
				},
				{
					zone_name: "banner",
					zone_data: {
						image_desktop:
							"//theme.hstatic.net/1000340570/1000885609/14/banner-of-the-year-desk.jpg?v=100817",
						image_mobile:
							"//theme.hstatic.net/1000340570/1000885609/14/banner-of-the-year-mobile.jpg?v=100817",
					},
				},
				{
					zone_name: "sub_banner",
					zone_data: [
						{
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_1.jpg?v=100817",
							url: "/products/mini-gift-set-burberry-london-4sp-5ml-x-4",
						},
						{
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_2.jpg?v=100817",
							url: "/products/gift-set-calvin-klein-euphoria-eau-de-parfum-3pcs-edp-100ml-edp-10ml-body-lotion-200ml",
						},
						{
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_3.jpg?v=100817",
							url: "/products/gift-set-chloe-nomade-3pcs-edp-75ml-edp-5ml-duong-the-100ml",
						},
					],
				},
				{
					zone_name: "info",
					zone_data: {
						title: "BRAND OF THE YEAR",
						descriptions: [
							"Săn Ngay Deal Khủng Từ Những Thương Hiệu Nước Hoa",
							"Được Yêu Thích Nhất của namperfume Trong Năm 2023",
						],
						slide: [
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_3.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_4.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_5.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_6.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_7.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
							},
						],
						grid: [
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_1.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_2.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_3.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_4.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_5.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_6.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_7.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_8.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_9.jpg?v=100817",
							},
						],
					},
				},
				{
					zone_name: "product",
					zone_data: {
						available_type: [
							{
								from_time: 1665371647,
								to_time: 1665371647,
							},
						],
						collection_id: 1003916686,
						collection_title: "BRANDBURBERRY",
						collection_handle: "brandburberry",
						image_desktop:
							"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
						image_mobile:
							"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
					},
				},
				{
					zone_name: "brandlist",
					zone_data: [
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
						},
					],
				},
				{
					zone_name: "banner",
					zone_data: {
						image_desktop:
							"//theme.hstatic.net/1000340570/1000885609/14/banner-of-the-year-desk.jpg?v=100817",
						image_mobile:
							"//theme.hstatic.net/1000340570/1000885609/14/banner-of-the-year-mobile.jpg?v=100817",
					},
				},
				{
					zone_name: "sub_banner",
					zone_data: [
						{
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_1.jpg?v=100817",
							url: "/products/mini-gift-set-burberry-london-4sp-5ml-x-4",
						},
						{
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_2.jpg?v=100817",
							url: "/products/gift-set-calvin-klein-euphoria-eau-de-parfum-3pcs-edp-100ml-edp-10ml-body-lotion-200ml",
						},
						{
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_3.jpg?v=100817",
							url: "/products/gift-set-chloe-nomade-3pcs-edp-75ml-edp-5ml-duong-the-100ml",
						},
					],
				},
				{
					zone_name: "info",
					zone_data: {
						title: "BRAND OF THE YEAR",
						descriptions: [
							"Săn Ngay Deal Khủng Từ Những Thương Hiệu Nước Hoa",
							"Được Yêu Thích Nhất của namperfume Trong Năm 2023",
						],
						slide: [
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_3.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_4.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_5.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_6.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_7.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
							},
						],
						grid: [
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_1.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_2.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_3.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_4.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_5.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_6.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_7.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_8.jpg?v=100817",
							},
							{
								url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
								img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_9.jpg?v=100817",
							},
						],
					},
				},
				{
					zone_name: "product",
					zone_data: {
						available_type: [
							{
								from_time: 1665371647,
								to_time: 1665371647,
							},
						],
						collection_id: 1003916686,
						collection_title: "BRANDBURBERRY",
						collection_handle: "brandburberry",
						image_desktop:
							"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
						image_mobile:
							"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
					},
				},
				{
					zone_name: "brandlist",
					zone_data: [
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
						},
						{
							url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
						},
					],
				},
			],
		},
		status: 1,
	};

	const ii: DataZone[] = [
		{
			zone_name: "banner",
			zone_data: {
				image_desktop:
					"//theme.hstatic.net/1000340570/1000885609/14/banner-of-the-year-desk.jpg?v=100817",
				image_mobile:
					"//theme.hstatic.net/1000340570/1000885609/14/banner-of-the-year-mobile.jpg?v=100817",
			},
		},
		{
			zone_name: "sub_banner",
			zone_data: [
				{
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_1.jpg?v=100817",
					url: "/products/mini-gift-set-burberry-london-4sp-5ml-x-4",
				},
				{
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_2.jpg?v=100817",
					url: "/products/gift-set-calvin-klein-euphoria-eau-de-parfum-3pcs-edp-100ml-edp-10ml-body-lotion-200ml",
				},
				{
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_2_product_tren_3.jpg?v=100817",
					url: "/products/gift-set-chloe-nomade-3pcs-edp-75ml-edp-5ml-duong-the-100ml",
				},
			],
		},
		{
			zone_name: "info",
			zone_data: {
				title: "BRAND OF THE YEAR",
				descriptions: [
					"Săn Ngay Deal Khủng Từ Những Thương Hiệu Nước Hoa",
					"Được Yêu Thích Nhất của namperfume Trong Năm 2023",
				],
				slide: [
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_3.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_4.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_5.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_6.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_7.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_8.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_9.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_1.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_tren_2.jpg?v=100817",
					},
				],
				grid: [
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_1.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_2.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_3.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_4.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_5.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_6.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_7.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_8.jpg?v=100817",
					},
					{
						url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
						img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_doc_9.jpg?v=100817",
					},
				],
			},
		},
		{
			zone_name: "product",
			zone_data: {
				available_type: [
					{
						from_time: 1665371647,
						to_time: 1716019486,
					},
				],
				style: {
					bg: "#E7BDA5",
				},
				collection_id: 1003916686,
				collection_title: "BRANDBURBERRY",
				collection_handle: "brandburberry",
				image_desktop:
					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
				image_mobile:
					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
			},
		},
		{
			zone_name: "brandlist",
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
				},
			],
		},
		{
			zone_name: "product",
			zone_data: {
				available_type: [
					{
						from_time: 1665371647,
						to_time: 1716624286,
					},
				],
				style: {
					bg: "#D87786",
				},
				collection_id: 1003916686,
				collection_title: "BRANDBURBERRY",
				collection_handle: "brandburberry",
				image_desktop:
					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
				image_mobile:
					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
			},
		},
		{
			zone_name: "brandlist",
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
				},
			],
		},
		{
			zone_name: "product",
			zone_data: {
				available_type: [
					{
						from_time: 1665371647,
						to_time: 1716192286,
					},
				],
				style: {
					bg: "#CEB389",
				},
				collection_id: 1003916686,
				collection_title: "BRANDBURBERRY",
				collection_handle: "brandburberry",
				image_desktop:
					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1.jpg",
				image_mobile:
					"//theme.hstatic.net/1000340570/1000885609/14/brandyear2_banner_tren_1_mob.jpg",
			},
		},
		{
			zone_name: "brandlist",
			zone_data: [
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/burberry",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_1.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_2.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_3.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_4.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/chloe",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_5.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/ralph-lauren",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_6.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/jo-malone",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_7.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/perris-monte-carlo",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_8.jpg?v=100817",
				},
				{
					url: "/blogs/thuong-hieu-nuoc-hoa/dsquared2",
					img: "//theme.hstatic.net/1000340570/1000885609/14/brandyear2_brand_ngang_9.jpg?v=100817",
				},
			],
		},
		{
			zone_name: "support",
			zone_data: {
				image_mobile:
					"//theme.hstatic.net/1000340570/1000885609/14/brandyear_uudai_mob.jpg?v=100824",
				image_desktop:
					"//theme.hstatic.net/1000340570/1000885609/14/brandyear_uudai_desk.jpg?v=100824",
			},
		},
	];

	const iiii = {
		company_id: 1,
		key: "menu_home",
		group: "SHOP",
		id: 480,
		lang: "en",
		value: [
			{
				name: "namperfume Favorites",
				link: "/blogs/thuong-hieu-nuoc-hoa/namperfume-favorites",
				is_event: true,
				submenu: null,
				from_time: 1712136268,
				to_time: 0,
				id: 2,
			},
			{
				name: "Deal Thơm",
				link: "/collections/deal-thom",
				submenu: null,
				is_event: true,
				from_time: 1712136268,
				to_time: 1714466992,
				id: 1,
			},
			{
				name: "Male Perfume",
				link: "/collections/nuoc-hoa-nam",
				submenu: {
					category: [
						{
							id: 1,
							type: "Category",
							details: [
								{
									link: "/collections/nuoc-hoa-nam?sort_by=date_created&sort_type=DESC&page=1",
									title: "Latest",
								},
								{
									link: "/collections/nuoc-hoa-nam?sort_by=best_seller&sort_type=DESC&page=1",
									title: "Best selling",
								},
								{
									link: "/collections/nuoc-hoa-niche",
									title: "Niche Perfume",
								},
								{
									link: "/collections/giftset-nam",
									title: "Gift set",
								},
								{
									link: "/collections/nuoc-hoa-unisex",
									title: "Unisex Perfume",
								},
								{
									link: "/collections/nuoc-hoa-mini",
									title: "Mini Perfume",
								},
							],
						},
						{
							id: 2,
							type: "Brand",
							is_brand: true,
							details: [
								{
									title: "GUCCI",
									link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
								},
								{
									title: "CALVIN KLEIN",
									link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
								},
								{
									title: "NARCISO RODRIGUEZ",
									link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
								},
								{
									title: "CHANEL",
									link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
								},
								{
									title: "BURBERRY",
									link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								},
								{
									title: "BVLGARI",
									link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
								},
								{
									title: "VERSACE",
									link: "/blogs/thuong-hieu-nuoc-hoa/versace",
								},
								{
									title: "HERMES",
									link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
								},
								{
									title: "JO MALONE",
									link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone-london",
								},
								{
									title: "CHRISTIAN DIOR",
									link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
								},
								{
									title: "VALENTINO",
									link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
								},
								{
									title: "HUGO BOSS",
									link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
								},
								{
									title: "CAROLINA HERRERA",
									link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
								},
								{
									title: "JEAN PAUL GAULTIER",
									link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
								},
								{
									title: "YVES SAINT LAURENT",
									link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
								},
								{
									title: "View all",
									link: "/collections/all",
								},
							],
						},
					],
					images: [
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nam_1.jpg",
							alt: "alt2",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nam_2.jpg",
							alt: "alt3",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nam_3.jpg",
							alt: "alt4",
						},
					],
				},
			},
			{
				name: "Female Perfume",
				link: "/collections/nuoc-hoa-nu",
				submenu: {
					category: [
						{
							id: 1,
							type: "Category",
							details: [
								{
									link: "/collections/nuoc-hoa-nu?sort_by=date_created&sort_type=DESC&page=1",
									title: "Latest",
								},
								{
									link: "/collections/nuoc-hoa-nu?sort_by=best_seller&sort_type=DESC&page=1",
									title: "Best selling",
								},
								{
									link: "/collections/nuoc-hoa-niche",
									title: "Niche Perfume",
								},
								{
									link: "/collections/giftset-nu",
									title: "Gift set",
								},
								{
									link: "/collections/nuoc-hoa-unisex",
									title: "Unisex Perfume",
								},
								{
									link: "/collections/nuoc-hoa-mini",
									title: "Mini Perfume",
								},
							],
						},
						{
							id: 2,
							type: "Brand",
							details: [
								{
									title: "GUCCI",
									link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
								},
								{
									title: "CALVIN KLEIN",
									link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
								},
								{
									title: "NARCISO RODRIGUEZ",
									link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
								},
								{
									title: "CHANEL",
									link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
								},
								{
									title: "BURBERRY",
									link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								},
								{
									title: "BVLGARI",
									link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
								},
								{
									title: "VERSACE",
									link: "/blogs/thuong-hieu-nuoc-hoa/versace",
								},
								{
									title: "HERMES",
									link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
								},
								{
									title: "JO MALONE",
									link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone-london",
								},
								{
									title: "CHRISTIAN DIOR",
									link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
								},
								{
									title: "VALENTINO",
									link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
								},
								{
									title: "HUGO BOSS",
									link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
								},
								{
									title: "CAROLINA HERRERA",
									link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
								},
								{
									title: "JEAN PAUL GAULTIER",
									link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
								},
								{
									title: "YVES SAINT LAURENT",
									link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
								},
								{
									title: "View all",
									link: "/collections/all",
								},
							],
						},
					],
					images: [
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nu_1.jpg",
							alt: "first",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nu_2.jpg",
							alt: "second",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nu_3.jpg",
							alt: "third",
						},
					],
				},
				from_time: 0,
				to_time: 0,
			},
			{
				name: "Mini Perfume",
				link: "/collections/nuoc-hoa-mini",
				submenu: {
					category: [
						{
							id: 1,
							type: "Category",
							details: [
								{
									link: "/collections/nuoc-hoa-mini?sort_by=date_created&sort_type=DESC&page=1",
									title: "Latest",
								},
								{
									link: "/collections/nuoc-hoa-mini?sort_by=best_seller&sort_type=DESC&page=1",
									title: "Best selling",
								},
								{
									link: "/collections/nuoc-hoa-niche",
									title: "Niche Perfume",
								},
								{
									link: "/collections/giftset-nuoc-hoa-mini",
									title: "Gift set",
								},
								{
									link: "/collections/nuoc-hoa-unisex",
									title: "Unisex Perfume",
								},
								{
									link: "/collections/nuoc-hoa-mini",
									title: "Mini Perfume",
								},
							],
						},
						{
							id: 2,
							type: "Brand",
							details: [
								{
									title: "GUCCI",
									link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
								},
								{
									title: "CALVIN KLEIN",
									link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
								},
								{
									title: "NARCISO RODRIGUEZ",
									link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
								},
								{
									title: "CHANEL",
									link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
								},
								{
									title: "BURBERRY",
									link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								},
								{
									title: "BVLGARI",
									link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
								},
								{
									title: "VERSACE",
									link: "/blogs/thuong-hieu-nuoc-hoa/versace",
								},
								{
									title: "HERMES",
									link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
								},
								{
									title: "JO MALONE",
									link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone-london",
								},
								{
									title: "CHRISTIAN DIOR",
									link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
								},
								{
									title: "VALENTINO",
									link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
								},
								{
									title: "HUGO BOSS",
									link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
								},
								{
									title: "CAROLINA HERRERA",
									link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
								},
								{
									title: "JEAN PAUL GAULTIER",
									link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
								},
								{
									title: "YVES SAINT LAURENT",
									link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
								},
								{
									title: "View all",
									link: "/collections/all",
								},
							],
						},
					],
					images: [
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_mini_1.jpg",
							alt: "first",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_mini_2.jpg",
							alt: "second",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_mini_3.jpg",
							alt: "third",
						},
					],
				},
				from_time: 0,
				to_time: 0,
			},
			{
				name: "Giftset",
				link: "/collections/giftset-nuoc-hoa",
				submenu: {
					category: [
						{
							id: 1,
							type: "Category",
							details: [
								{
									link: "/collections/giftset-nam",
									title: "Gift set nước hoa nam",
								},
								{
									link: "/collections/giftset-nu",
									title: "Gift set Female Perfume",
								},
								{
									link: "/collections/giftset-nuoc-hoa-mini",
									title: "Gift set Mini Perfume",
								},
							],
						},
						{
							id: 2,
							type: "Price",
							details: [
								{
									title: "Under 1.000.000",
									link: "/collections/giftset-nuoc-hoa?price_max=1000000&price_min=0&page=1",
								},
								{
									title: "Under 2.000.000",
									link: "/collections/giftset-nuoc-hoa?price_max=2000000&price_min=0&page=1",
								},
								{
									title: "Over 2.000.000",
									link: "/collections/giftset-nuoc-hoa?price_min=2000000&page=1",
								},
							],
						},
					],
					images: [
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_giftset_1.jpg",
							alt: "first",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_giftset_2.jpg",
							alt: "second",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_giftset_3.jpg",
							alt: "third",
						},
					],
				},
				from_time: 0,
				to_time: 0,
			},
			{
				name: "Niche Perfume",
				link: "/collections/nuoc-hoa-niche",
				submenu: null,
			},
			{
				name: "Brand",
				link: "/pages/thuong-hieu-a-z",
				submenu: {
					category: [
						{
							id: 1,
							type: "Brand new",
							details: [
								{
									link: "/blogs/thuong-hieu-nuoc-hoa/maison-margiela",
									title: "MAISON MARGIELA",
								},
								{
									link: "/blogs/thuong-hieu-nuoc-hoa/kilian",
									title: "KILIAN",
								},
								{
									link: "/blogs/thuong-hieu-nuoc-hoa/mancera",
									title: "MANCERA",
								},
								{
									link: "/blogs/thuong-hieu-nuoc-hoa/parfums-de-marly",
									title: "PERFUMS DE MARLY",
								},
								{
									link: "/blogs/thuong-hieu-nuoc-hoa/creed",
									title: "CREED",
								},
								{
									link: "/pages/thuong-hieu-a-z",
									title: "Brand A-Z",
								},
							],
						},
						{
							id: 2,
							type: "Brand",
							details: [
								{
									title: "GUCCI",
									link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
								},
								{
									title: "CALVIN KLEIN",
									link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
								},
								{
									title: "NARCISO RODRIGUEZ",
									link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
								},
								{
									title: "CHANEL",
									link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
								},
								{
									title: "BURBERRY",
									link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
								},
								{
									title: "BVLGARI",
									link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
								},
								{
									title: "VERSACE",
									link: "/blogs/thuong-hieu-nuoc-hoa/versace",
								},
								{
									title: "HERMES",
									link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
								},
								{
									title: "JO MALONE",
									link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone-london",
								},
								{
									title: "CHRISTIAN DIOR",
									link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
								},
								{
									title: "VALENTINO",
									link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
								},
								{
									title: "HUGO BOSS",
									link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
								},
								{
									title: "CAROLINA HERRERA",
									link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
								},
								{
									title: "JEAN PAUL GAULTIER",
									link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
								},
								{
									title: "YVES SAINT LAURENT",
									link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
								},
							],
						},
					],
					images: [
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_brand_1.jpg",
							alt: "first",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_brand_2.jpg",
							alt: "second",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_brand_3.jpg",
							alt: "third",
						},
					],
				},
				from_time: 0,
				to_time: 0,
			},
			{
				name: "Bodycare & Homecare",
				link: "/collections/bodycare-homecare",
				submenu: {
					category: [
						{
							id: 1,
							type: "Category",
							details: [
								{
									link: "/collections/cham-soc-toc",
									title: "Hair",
								},
								{
									link: "/collections/cham-soc-da",
									title: "Skin",
								},
								{
									link: "/collections/tinh-dau-nuoc-hoa",
									title: "Perfume oils",
								},
								{
									link: "/collections/lan-khu-mui",
									title: "Roller",
								},
								{
									link: "/collections/thuc-pham-chuc-nang",
									title: "Food",
								},
								{
									link: "/collections/qua-tang",
									title: "Gift",
								},
								{
									link: "/collections/hop-qua",
									title: "Gift box",
								},
							],
						},
					],
					images: [
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_bodyhomecare_1.jpg",
							alt: "alt2",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_bodyhomecare_2.jpg",
							alt: "alt3",
						},
						{
							url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_bodyhomecare_3.jpg",
							alt: "alt4",
						},
					],
				},
			},
			{
				name: "Lipstick",
				link: "/collections/son-moi",
				submenu: null,
			},
		],
		status: 1,
	};
	const eng = [
		{
			name: "namperfume Favorites",
			link: "/blogs/thuong-hieu-nuoc-hoa/namperfume-favorites",
			is_event: true,
			submenu: null,
			from_time: 1712136268,
			to_time: 0,
			id: 2,
		},
		{
			name: "Deal Thơm",
			link: "/collections/deal-thom",
			submenu: null,
			is_event: true,
			from_time: 1712136268,
			to_time: 1714466992,
			id: 1,
		},
		{
			name: "Male Perfume",
			link: "/collections/nuoc-hoa-nam",
			submenu: {
				category: [
					{
						id: 1,
						type: "Category",
						details: [
							{
								link: "/collections/nuoc-hoa-nam?sort_by=date_created&sort_type=DESC&page=1",
								title: "Latest",
							},
							{
								link: "/collections/nuoc-hoa-nam?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Best selling",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Niche Perfume",
							},
							{
								link: "/collections/giftset-nam",
								title: "Gift set",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Unisex Perfume",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Mini Perfume",
							},
						],
					},
					{
						id: 2,
						type: "Brand",
						is_brand: true,
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone-london",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "View all",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nam_1.jpg",
						alt: "alt2",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nam_2.jpg",
						alt: "alt3",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nam_3.jpg",
						alt: "alt4",
					},
				],
			},
		},
		{
			name: "Female Perfume",
			link: "/collections/nuoc-hoa-nu",
			submenu: {
				category: [
					{
						id: 1,
						type: "Category",
						details: [
							{
								link: "/collections/nuoc-hoa-nu?sort_by=date_created&sort_type=DESC&page=1",
								title: "Latest",
							},
							{
								link: "/collections/nuoc-hoa-nu?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Best selling",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Niche Perfume",
							},
							{
								link: "/collections/giftset-nu",
								title: "Gift set",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Unisex Perfume",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Mini Perfume",
							},
						],
					},
					{
						id: 2,
						type: "Brand",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone-london",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "View all",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nu_1.jpg",
						alt: "first",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nu_2.jpg",
						alt: "second",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_nu_3.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
		},
		{
			name: "Mini Perfume",
			link: "/collections/nuoc-hoa-mini",
			submenu: {
				category: [
					{
						id: 1,
						type: "Category",
						details: [
							{
								link: "/collections/nuoc-hoa-mini?sort_by=date_created&sort_type=DESC&page=1",
								title: "Latest",
							},
							{
								link: "/collections/nuoc-hoa-mini?sort_by=best_seller&sort_type=DESC&page=1",
								title: "Best selling",
							},
							{
								link: "/collections/nuoc-hoa-niche",
								title: "Niche Perfume",
							},
							{
								link: "/collections/giftset-nuoc-hoa-mini",
								title: "Gift set",
							},
							{
								link: "/collections/nuoc-hoa-unisex",
								title: "Unisex Perfume",
							},
							{
								link: "/collections/nuoc-hoa-mini",
								title: "Mini Perfume",
							},
						],
					},
					{
						id: 2,
						type: "Brand",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone-london",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
							{
								title: "View all",
								link: "/collections/all",
							},
						],
					},
				],
				images: [
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_mini_1.jpg",
						alt: "first",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_mini_2.jpg",
						alt: "second",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_mini_3.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
		},
		{
			name: "Giftset",
			link: "/collections/giftset-nuoc-hoa",
			submenu: {
				category: [
					{
						id: 1,
						type: "Category",
						details: [
							{
								link: "/collections/giftset-nam",
								title: "Gift set nước hoa nam",
							},
							{
								link: "/collections/giftset-nu",
								title: "Gift set Female Perfume",
							},
							{
								link: "/collections/giftset-nuoc-hoa-mini",
								title: "Gift set Mini Perfume",
							},
						],
					},
					{
						id: 2,
						type: "Price",
						details: [
							{
								title: "Under 1.000.000",
								link: "/collections/giftset-nuoc-hoa?price_max=1000000&price_min=0&page=1",
							},
							{
								title: "Under 2.000.000",
								link: "/collections/giftset-nuoc-hoa?price_max=2000000&price_min=0&page=1",
							},
							{
								title: "Over 2.000.000",
								link: "/collections/giftset-nuoc-hoa?price_min=2000000&page=1",
							},
						],
					},
				],
				images: [
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_giftset_1.jpg",
						alt: "first",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_giftset_2.jpg",
						alt: "second",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_giftset_3.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
		},
		{
			name: "Niche Perfume",
			link: "/collections/nuoc-hoa-niche",
			submenu: null,
		},
		{
			name: "Brand",
			link: "/pages/thuong-hieu-a-z",
			submenu: {
				category: [
					{
						id: 1,
						type: "Brand new",
						details: [
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/maison-margiela",
								title: "MAISON MARGIELA",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/kilian",
								title: "KILIAN",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/mancera",
								title: "MANCERA",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/parfums-de-marly",
								title: "PERFUMS DE MARLY",
							},
							{
								link: "/blogs/thuong-hieu-nuoc-hoa/creed",
								title: "CREED",
							},
							{
								link: "/pages/thuong-hieu-a-z",
								title: "Brand A-Z",
							},
						],
					},
					{
						id: 2,
						type: "Brand",
						details: [
							{
								title: "GUCCI",
								link: "/blogs/thuong-hieu-nuoc-hoa/gucci",
							},
							{
								title: "CALVIN KLEIN",
								link: "/blogs/thuong-hieu-nuoc-hoa/calvin-klein",
							},
							{
								title: "NARCISO RODRIGUEZ",
								link: "/blogs/thuong-hieu-nuoc-hoa/narciso-rodriguez",
							},
							{
								title: "CHANEL",
								link: "/blogs/thuong-hieu-nuoc-hoa/chanel",
							},
							{
								title: "BURBERRY",
								link: "/blogs/thuong-hieu-nuoc-hoa/burberry",
							},
							{
								title: "BVLGARI",
								link: "/blogs/thuong-hieu-nuoc-hoa/bvlgari",
							},
							{
								title: "VERSACE",
								link: "/blogs/thuong-hieu-nuoc-hoa/versace",
							},
							{
								title: "HERMES",
								link: "/blogs/thuong-hieu-nuoc-hoa/hermes",
							},
							{
								title: "JO MALONE",
								link: "/blogs/thuong-hieu-nuoc-hoa/jo-malone-london",
							},
							{
								title: "CHRISTIAN DIOR",
								link: "/blogs/thuong-hieu-nuoc-hoa/christian-dior",
							},
							{
								title: "VALENTINO",
								link: "/blogs/thuong-hieu-nuoc-hoa/valentino",
							},
							{
								title: "HUGO BOSS",
								link: "/blogs/thuong-hieu-nuoc-hoa/hugoboss",
							},
							{
								title: "CAROLINA HERRERA",
								link: "/blogs/thuong-hieu-nuoc-hoa/carolina-herrera",
							},
							{
								title: "JEAN PAUL GAULTIER",
								link: "/blogs/thuong-hieu-nuoc-hoa/jean-paul-gaultier",
							},
							{
								title: "YVES SAINT LAURENT",
								link: "/blogs/thuong-hieu-nuoc-hoa/yves-saint-laurent",
							},
						],
					},
				],
				images: [
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_brand_1.jpg",
						alt: "first",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_brand_2.jpg",
						alt: "second",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_brand_3.jpg",
						alt: "third",
					},
				],
			},
			from_time: 0,
			to_time: 0,
		},
		{
			name: "Bodycare & Homecare",
			link: "/collections/bodycare-homecare",
			submenu: {
				category: [
					{
						id: 1,
						type: "Category",
						details: [
							{
								link: "/collections/cham-soc-toc",
								title: "Hair",
							},
							{
								link: "/collections/cham-soc-da",
								title: "Skin",
							},
							{
								link: "/collections/tinh-dau-nuoc-hoa",
								title: "Perfume oils",
							},
							{
								link: "/collections/lan-khu-mui",
								title: "Roller",
							},
							{
								link: "/collections/thuc-pham-chuc-nang",
								title: "Food",
							},
							{
								link: "/collections/qua-tang",
								title: "Gift",
							},
							{
								link: "/collections/hop-qua",
								title: "Gift box",
							},
						],
					},
				],
				images: [
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_bodyhomecare_1.jpg",
						alt: "alt2",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_bodyhomecare_2.jpg",
						alt: "alt3",
					},
					{
						url: "https://theme.hstatic.net/1000340570/1000964732/14/menu_hover_bodyhomecare_3.jpg",
						alt: "alt4",
					},
				],
			},
		},
		{
			name: "Lipstick",
			link: "/collections/son-moi",
			submenu: null,
		},
	];

	const config = {
		company_id: 10308,
		key: "en.namperfume.net_config",
		group: "COMMON",
		id: 453,
		lang: "en",
		value: {
			key: "en.namefragrance.vn_config",
			lang: "en",
			label: "Englist",
			url: "https://en.namefragrance.vn",
			expired_translate: "3600",
			version: "1.0.1",
		},
		status: 1,
	};

	return (
		<div className={styles.wrapper}>
			{data &&
				data.value.map((i: DataZone, index) => {
					if (i.zone_name === "banner") {
						return (
							<EZoneDataBanner
								key={index}
								data={i.zone_data as ZoneDataBanner}></EZoneDataBanner>
						);
					}
					if (i.zone_name === "sub_banner") {
						return (
							<EZoneDataSubBanner
								key={index}
								data={i.zone_data as ZoneDataSubBanner[]}></EZoneDataSubBanner>
						);
					}

					if (i.zone_name === "info") {
						return (
							<EZoneDataInfo
								key={index}
								data={i.zone_data as ZoneDataInfo}></EZoneDataInfo>
						);
					}

					if (i.zone_name === "product") {
						return (
							<EZoneDataProduct
								key={index}
								data={i.zone_data as ZoneDataProduct}></EZoneDataProduct>
						);
					}

					if (i.zone_name === "brandlist") {
						return (
							<EZoneDataBrand
								key={index}
								data={i.zone_data as ZoneDataBrandList[]}></EZoneDataBrand>
						);
					}

					if (i.zone_name === "support") {
						return (
							<EZoneDataSupport
								data={i.zone_data as ZoneDataSupport}></EZoneDataSupport>
						);
					}

					return null;
				})}
		</div>
	);
}
