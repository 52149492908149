import React from "react";
import { ZoneDataSubBanner } from "../types/event.type";
import style from "../css/event.module.css";
export default function EZoneDataSubBanner({
	data,
}: {
	data: ZoneDataSubBanner[];
}) {
	return (
		<div className={style.sub_wrapper}>
			{data.map((item, index) => {
				return (
					<a key={index} className="w-full" href={item.url}>
						<img className="" src={item.img} alt="img_sub" />
					</a>
				);
			})}
		</div>
	);
}
