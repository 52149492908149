import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";

interface InititalState {
	data: any[];
}

// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount: number, thunkAPI) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

const initialState: InititalState = {
	data: [],
};

export const EventSlice = createSlice({
	name: "auth",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setToken: (state, action) => {
			localStorage.setItem("MAIN:token", JSON.stringify(action.payload));
		},
	},
});

export const { setToken } = EventSlice.actions;

export default EventSlice.reducer;
