import React from "react";
import style from "../css/event.module.css";
import { ZoneDataBrandList } from "../types/event.type";

export default function EZoneDataBrand({
	data,
}: {
	data: ZoneDataBrandList[];
}) {
	return (
		<div className={style.brand_wrapper}>
			{data.map((i, index) => (
				<a key={index} href={i.url} className={style["a"]}>
					<img src={i.img} alt="img_brand" className={style["img"]} />
				</a>
			))}
		</div>
	);
}
