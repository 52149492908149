import React, { useMemo } from "react";
import { ZoneDataProduct } from "../types/event.type";
import style from "../css/event.module.css";
import { useGetDataProductQuery } from "../service/product.service";
import { AvailableType } from "../types/event.type";
import type { CountdownProps } from "antd";
import { Statistic } from "antd";
import { data } from "features/comment/Data";
type CountDownProps = {
	available: AvailableType;
	onFinish: () => void;
};
const { Countdown } = Statistic;
export default function EZoneDataProduct({ data }: { data: ZoneDataProduct }) {
	const { data: dataProduct, isError } = useGetDataProductQuery({
		page: 1,
		limit: 8,
		collection_id: "nuoc-hoa-nam",
		store_id: 169185,
	});

	const available = useMemo(() => {
		let result = null;
		data.available_type.forEach((a) => {});
	}, [data]);
	if (isError) {
		return null;
	}

	//function

	const addItem = async (id: number) => {
		const formData = new FormData();
		formData.append("quanity", "1");
		formData.append("id", id.toString());

		try {
			await fetch("/cart/add.js", {
				method: "POST",
				body: formData,
			});
		} catch (error) {}
	};

	const byNow = async (id: number) => {
		const formData = new FormData();
		formData.append("quanity", "1");
		formData.append("id", id.toString());

		try {
			await fetch("/cart/add.js", {
				method: "POST",
				body: formData,
			}).then((res: any) => {
				if (res.ok) {
					window.location.href = "/pages/checkouts";
				} else {
					window.alert("Mua ngay loi");
				}
			});
		} catch (error) {}
	};
	return (
		<div
			style={{ "--bgcolor": `${data.style.bg}` } as React.CSSProperties}
			className={`${style.product_wrapper} `}>
			<CountDownTime
				available={data.available_type[0]}
				onFinish={() => {}}></CountDownTime>
			<div className={`${style.container} ${style.product_flex}`}>
				<div className={style.product_left}>
					<picture className={style["picture"]}>
						<source media="(max-width: 768px)" srcSet={data.image_mobile} />
						<img
							className={style["img"]}
							src={data.image_desktop}
							alt={"img_banner"}
						/>
					</picture>
				</div>

				<div className={style.product_right}>
					{dataProduct &&
						dataProduct.items.map((p, index) => {
							return (
								<div key={index} className={style.productcard}>
									<div className={style.product_image}>
										<img src={p.images?.[0].url || ""} alt={`img_${p.sku}`} />
									</div>
									<div className={style.product_des}>
										<p className={style["brand"]}>{p.brand?.title}</p>
										<p className={style["fullname"]}>{p.full_name}</p>
										<div className={style["prices"]}>
											<p className={style.price_final}>
												{p.price
													.toLocaleString("vi-VN", {
														style: "currency",
														currency: "VND",
													})
													.replace(/&nbsp;/g, " ")}
											</p>

											{p.compare_at_price > 0 && (
												<p className={style.price_compare}>
													{p.compare_at_price
														.toLocaleString("vi-VN", {
															style: "currency",
															currency: "VND",
														})
														.replace(/&nbsp;/g, " ")}{" "}
												</p>
											)}
										</div>
										<div className={style["action"]}>
											<button
												className="argos-btn-add-to-cart buy action"
												onClick={() => addItem(p.id)}>
												THÊM VÀO GIỎ
											</button>
											<button
												className="argos-btn-buy-now action"
												type="submit"
												title="Buy now"
												onClick={() => byNow(p.id)}>
												MUA NGAY
											</button>
										</div>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
}

const CountDownTime = ({ available, onFinish }: CountDownProps) => {
	const from = available.from_time;
	const to = available.to_time;
	const valueCount = to - from;
	return (
		<div className={style["countdown_wrapper"]}>
			<Countdown
				title={<h2 className={style["h2"]}>Chương trình diễn ra sau</h2>}
				value={Date.now() + valueCount}
				onFinish={onFinish}
				className={style["countdown_count"]}
			/>
		</div>
	);
};
