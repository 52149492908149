import React from "react";
import { ZoneDataInfo } from "../types/event.type";
import { Autoplay, FreeMode, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ZoneImage } from "../types/event.type";
import style from "../css/event.module.css";

export default function EZoneDataInfo({ data }: { data: ZoneDataInfo }) {
	return (
		<div className={`${style.infor_wrapper} ${style.container}`}>
			<div className={style.infor_title}>
				<h1 className={style["h1"]}>{data.title}</h1>
				{data.descriptions.map((des, index) => {
					return (
						<p className={style["p"]} key={index}>
							{des}
						</p>
					);
				})}
			</div>
			<div className={style.infor_brand}>
				<div className={style.infor_brand__slide}>
					<Swiper
						// install Swiper modules
						id={"s"}
						modules={[Navigation, Pagination, Scrollbar, Autoplay, FreeMode]}
						effect="slide"
						freeMode={false}
						edgeSwipeDetection={true}
						autoplay={false}
						spaceBetween={0}
						slidesPerView={1}
						// initialSlide={initialSlide}
					>
						{data.slide.map((item: ZoneImage, index) => {
							return (
								<SwiperSlide key={index}>
									<a href={item.url}>
										<img
											className={style["image"]}
											style={{ height: "100%" }}
											src={item.img}
											alt="img_slide"
										/>
									</a>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</div>
				<div className={style.infor_grid}>
					{data.grid.map((item, index) => (
						<a href={item.url} key={index} className={style["a"]}>
							<img
								className="w-full h-full object-cover border border-gray-300"
								src={item.img}
								alt="img_grid"
							/>
						</a>
					))}
				</div>
			</div>
		</div>
	);
}
