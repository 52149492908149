import React from "react";
import style from "../css/event.module.css";
import { ZoneDataSupport } from "../types/event.type";

export default function EZoneDataSupport({ data }: { data: ZoneDataSupport }) {
	return (
		<div className={style.support_wrapper}>
			123
			<picture>
				<source media="(max-width: 768px)" srcSet={data.image_mobile} />
				<img
					className="img-fluid mx-auto img"
					src={data.image_desktop}
					alt={"img_banner"}
				/>
			</picture>
		</div>
	);
}
