import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { RootState } from "app/store";
import Setting from "setting";

interface Params {
	__jwtAuthorization?: string;
}

export const baseQuery = (v2?: boolean) => {
	return fetchBaseQuery({
		// baseUrl: Setting.REACT_APP_BASE_URL,
		// // baseUrl: "https://api.namefragrance.vn/v1",
		baseUrl: v2
			? "https://api.namefragrance.vn/v2"
			: "https://api.namefragrance.vn/v1",

		// baseUrl: v2
		// 	? "https://ldevapi.namefragrance.vn/v2"
		// 	: "https://ldevapi.namefragrance.vn/v1",

		timeout: 10 * 1000,

		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set("Authorization", `${token}`);
			}
			return headers;
		},

		// credentials: "omit",
		paramsSerializer(params: Record<string, any> & Params) {
			const token = localStorage.getItem("MAIN:token");
			const filteredObj = Object.entries(params).reduce(
				(acc: any, [key, value]) => {
					if (value !== undefined && value !== null) {
						acc[key] = value;
					}
					return acc;
				},
				{}
			);

			let query = {
				...filteredObj,
				host: window.location.hostname,
			};
			// if (token) {
			//   query.__jwtAuthorization = JSON.parse(token);
			//   // query.__jwtAuthorization = "6caf9c01a9b439b96e5187ad7b08037c";
			// }
			return new URLSearchParams(query).toString();
		},
	});
};
