import React from "react";
import { ZoneDataBanner } from "../types/event.type";
import style from "../css/event.module.css";

export default function EZoneDataBanner({ data }: { data: ZoneDataBanner }) {
	return (
		<div className="w-full h-auto">
			<picture>
				<source media="(max-width: 768px)" srcSet={data.image_mobile} />
				<img
					className="img-fluid mx-auto img"
					src={data.image_desktop}
					alt={"img_banner"}
				/>
			</picture>
		</div>
	);
}
